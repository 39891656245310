.custom-input-number::-webkit-inner-spin-button,
.custom-input-number::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.new-bg {
    background-color: #F9F4F0;
}

.back-to-event {
    color: #475467;
    background: none;
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.first-line-icon {
    color: #475467;
}

.gap-40 {
    gap: 40px;
}

.rounded-bg {
    border-radius: 16px !important;
}

.logomark-border {
    border: 1px solid white;
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 1.5px 4.5px 0px #1018281A;
}

.sign-in-options {
    border-radius: 16px;
    color: #344054;
    border: 1px solid #D0D5DD;
    padding: 8px 16px;
    margin: 6px 0;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 #1018280D;
}

.sign-in-options:hover {
    cursor: default;
    scale: 1.02;
    background-color: #f9fafb !important;
}

.sign-in-options a {
    display: flex;
    gap: 10px;
    color: #344054;
    text-decoration: none;
}

.gap-12 {
    gap: 12px;
}

.change-facebook-color {
    color: #1877f2;
}

.card-title {
    color: #344054 !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0px !important;
}

.text-desc {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.event-title-checkout {
    color: #024ddf;
    /* background-color: #F9F4F0; */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.event-location-checkout {
    color: #121212;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.checkout-tickets {
    font-size: 16px;
    font-weight: 600;
    color: #101828;
    line-height: 24px;
}


.form-check-input[type=checkbox] {
    border-radius: 0;
    border-color: #F98A45;
    border-width: 2px;
    outline: #F98A45;
}

.form-check-box:hover {
    border-radius: #F98A45;
}

.form-check-input:checked {
    background-color: #F98A45 !important;
    border: none !important;
}

.form-check-input:focus {
    box-shadow: 0 0 0 1px #F98A45 !important;
}

.form-check-input:active {
    background-color: #F98A45 !important;
}

.total {
    font-size: 16px;
    color: #E45D09;
    font-weight: 500;
    line-height: 20px;
    gap: 5px;
}

.total-checkout {
    color: #E45D09;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.discount-message {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #008000;
}

.checkout-login-title {
    font-size: 16px;
    color: #101828;
    font-weight: 600;
    line-height: 24px;
}

.checkout-login-desc {
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    font-weight: 400;
}

.signup-input,
.signup-input-name,
.form-input4 {
    border: 1px solid #D0D5DD !important;
    padding: 8px 12px !important;
    border-radius: 8px !important;
    color: #667085 !important;
    box-shadow: 0px 1px 2px 0px #1018280D !important;
    background-color: #f9fafb !important;
}

.sign-up-label {
    color: #344054;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.coupon-code-container {
    position: relative;
    width: 100%;
}

.coupon-code-clear {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.coupon-code {
    border-top-left-radius: 8px;
    border: 1px solid #D0D5DD;
    border-right: 0px;
    padding: 8px 12px;
    background-color: #F9FAFB;
    color: #101828 !important;
    border-bottom-left-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    width: 100%;
}

.coupon-code:focus {
    border: 1px solid #D0D5DD !important;
    border-right: 0px !important;
}

.coupon-code::placeholder {
    color: #667085;
}

.coupon-apply {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid #D0D5DD;
    padding: 10px 14px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #344054;
}

.applied {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #079455;
    cursor: default;
}

.invalid-coupon {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #D92D20;
}

.coupon-discount {
    color: #667085;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.discount-price {
    letter-spacing: 1.5px;
}

.not-you-btn {
    background-color: transparent;
    border: none;
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    color: #E45D09;
    font-weight: 600;
    margin-top: 16px;
}

.mb-38 {
    margin-bottom: 38px;
}

.terms-and-conditions {
    border-radius: 16px !important;
    background-color: #FFFFFF;
}

.terms-heading {
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    font-weight: 600;
}


.collapse-icon {
    color: #667085;
    cursor: pointer;
}

.terms-text {
    border: 1px solid #D0D5DD;
    border-radius: 16px;
    box-shadow: 0px 1px 2px 0px #1018280D;
}

.terms-text ul li {
    list-style-type: decimal;
    color: #344054;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 5px 0;
    text-align: justify;
}

.form-check-label {
    color: #344054;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 16px 21px 24px 0px;
}


@media (max-width: 768px) {

    .event-title-checkout {
        color: #024ddf;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .event-location-checkout {
        color: #121212;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }
}